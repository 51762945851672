import React, { useState, useRef, useCallback } from "react";
import { ReactComponent as BoneSVG } from "../../assets/stone.svg";
import "./about.css";

function AboutCard({ data = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const nextFeature = useCallback(() => {
    if (data.length === 0) return;

    setAnimationClass("slide-out-left");
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      setAnimationClass("");
      setTimeout(() => {
        setAnimationClass("slide-in-right");
      }, 10);
    }, 200);
  }, [data.length]);

  const prevFeature = useCallback(() => {
    if (data.length === 0) return;

    setAnimationClass("slide-out-right");
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + data.length) % data.length
      );
      setAnimationClass("");
      setTimeout(() => {
        setAnimationClass("slide-in-left");
      }, 10);
    }, 200);
  }, [data.length]);

  const handleSwipe = useCallback(() => {
    if (touchStartX.current === null || touchEndX.current === null) return;

    const difference = touchStartX.current - touchEndX.current;
    if (difference > 50) {
      nextFeature();
    } else if (difference < -50) {
      prevFeature();
    }
  }, [nextFeature, prevFeature]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    handleSwipe();
    touchStartX.current = null;
    touchEndX.current = null;
  };

  return (
    <div className="about-card">
      <h2 className="about-card-h2">Feature & Innovation</h2>
      <div
        className="feature-content"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <button
          onClick={prevFeature}
          className="nav-button prev"
          aria-label="Previous Feature"
        >
          &lt;
        </button>
        <div className={`bone-container ${animationClass}`}>
          <BoneSVG className="bone-svg" />
          <div className="feature-text-container">
            <h3 className="feature-title">{data[currentIndex]?.title}</h3>
            <p className="feature-description">
              {data[currentIndex]?.description}
            </p>
          </div>
        </div>
        <button
          onClick={nextFeature}
          className="nav-button next"
          aria-label="Next Feature"
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

export default AboutCard;
