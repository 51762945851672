import React, { useState, useEffect, useCallback } from "react";
import "./home.css";
import LeftImage from "../../assets/mascot.svg";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useReadContracts, useWriteContract } from "wagmi";
import {
  LinearProgress,
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  Container,
  InputAdornment,
} from "@mui/material";
import { Twitter, Telegram } from "@mui/icons-material";
import SpookipupLogo from "../../assets/logo.png";
import { formatUnits, parseUnits } from "viem";
import sourceABI from "../../abi/MUSDT.json";
import saleABI from "../../abi/PrivatePresale.json";

const styles = {
  "&.MuiButton-outlined": {
    color: "white",
    border: "1px solid white",
  },
};

const usdtContract = {
  abi: sourceABI,
  address: process.env.REACT_APP_SOURCE_ADDRESS,
};

const saleContract = {
  abi: saleABI,
  address: process.env.REACT_APP_SALE_ADDRESS,
};

const SaleState = Object.freeze({
  0: "NotStarted",
  1: "Active",
  2: "Paused",
  3: "Ended",
});

const Home = () => {
  const [amount, setAmount] = useState(0);
  const { isConnected, address } = useAccount();
  const listingPrice = 0.05;

  const handleInputChange = (event) => {
    setAmount(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (amount < 0) {
      setAmount(0);
    } else if (amount > 10000) {
      setAmount(10000);
    }
  };

  const [stats, setStats] = useState({
    presalePrice: "",
    totalQuantity: "",
    remainingQuantity: "",
    soldQuantity: "",
    presaleState: SaleState[2],
  });

  const { data, isSuccess } = useReadContracts({
    contracts: [
      { ...saleContract, functionName: "getPresalePrice" },
      { ...saleContract, functionName: "getTotalQuantity" },
      { ...saleContract, functionName: "getRemainingQuantity" },
      { ...saleContract, functionName: "getSoldQuantity" },
      { ...saleContract, functionName: "getPresaleState" },
    ],
  });

  useEffect(() => {
    if (isSuccess) {
      console.log(data);
      setStats({
        presalePrice: formatUnits(data[0].result, 6),
        totalQuantity: formatUnits(data[1].result, 18),
        remainingQuantity: formatUnits(data[2].result, 18),
        soldQuantity: formatUnits(data[3].result, 18),
        presaleState: SaleState[data[4].result],
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setAmount(0);
    if (isConnected) {
    }
  }, [isConnected]);

  const loadStats = () => {
    switch (stats.presaleState) {
      case SaleState[0]: // Not Started
        return (
          <Box className="info-item">
            <Typography variant="h6" align="center">
              Sale is yet to start!
            </Typography>
          </Box>
        );
      case SaleState[1]: // Active
      case SaleState[2]: // Paused
        return (
          <>
            <Box className="info-item">
              <Typography>Status:</Typography>
              <Typography>{stats.presaleState}</Typography>
            </Box>
            <Box className="info-item">
              <Typography>Total:</Typography>
              <Typography>{stats.totalQuantity}</Typography>
            </Box>
            <Box className="info-item">
              <Typography>Remaining:</Typography>
              <Typography>{stats.remainingQuantity}</Typography>
            </Box>
            <Box className="info-item">
              <Typography>Price:</Typography>
              <Typography>{stats.presalePrice}</Typography>
            </Box>
            <Box className="info-item">
              <Typography>Listing Price:</Typography>
              <Typography>{listingPrice}</Typography>
            </Box>
          </>
        );
      case SaleState[3]: // Ended
        return (
          <Box className="info-item">
            <Typography variant="h6" align="center">
              Sale has ended, stay tuned for further updates.
            </Typography>
          </Box>
        );
      default:
        return (
          <Box className="info-item">
            <Typography>Loading...</Typography>
          </Box>
        );
    }
  };

  const { writeContractAsync: approveContractAsync, isSuccess: isUSDTApproved } = useWriteContract();
  const { writeContractAsync: buyTokens } = useWriteContract();

  const handleUSDTApproval = async () => {
    try {
      console.log("USDT Approval");
      await approveContractAsync({
        ...usdtContract,
        functionName: "approve",
        args: [
          process.env.REACT_APP_SALE_ADDRESS,
          parseUnits((amount * stats.presalePrice).toString(), 6)
        ]
      });
      console.log("Approval successful.");
    } catch (err) {
      console.error("Approval failed:", err);
    }
  };

  const handleBuy = useCallback(async () => {
    try {
      const res = await buyTokens({
        ...saleContract,
        functionName: "buyTokens",
        args: [address, amount],
      });
      console.log("Buy successful:", res);
    } catch (err) {
      console.error("Buy failed:", err);
    }
  }, [address, amount, buyTokens]);

  useEffect(() => {
    if (isUSDTApproved) {
      console.log("USDT Approved! Initiating buy request.");
      handleBuy();
    }
  }, [isUSDTApproved, handleBuy]);

  const initBuy = async () => {
    console.log("Buy Initiated");
    await handleUSDTApproval();
  };

  return (
    (<div id="home" className="section">
      <Typography variant="h2" align="center" color="white" gutterBottom>
        Welcome to Spookipup!
      </Typography>
      <div className="image-container">
        <div className="image-box-left">
          <img src={LeftImage} alt="Left" className="floating-image" />
        </div>
        <div className="image-box-right">
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.95)",
              borderRadius: "40px",
              p: 2,
              width: "100%",
              maxWidth: 800,
              height: "auto",
              mb: 2,
              animation: "movingBoxBorder 3s infinite linear",
              border: "4px solid #F36A1F",
            }}
            className="box-with-animation"
          >
            <Typography
              variant="h6"
              className="contract-address"
              align="center"
            >
              Contract Address: 0xABCDEF1234567890
            </Typography>
            <br />
            <br />
            <Box
              className="info"
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 1,
                mb: 2,
              }}
            >
              {loadStats()}
            </Box>
            {(stats.presaleState === SaleState[1] ||
              stats.presaleState === SaleState[2]) && (
                <>
                  <Box sx={{ width: "100%", mt: 2 }}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (Number(stats.soldQuantity) /
                          Number(stats.totalQuantity)) *
                        100
                      }
                      sx={{
                        height: 20,
                        borderRadius: 5,
                        "& .MuiLinearProgress-bar": {
                          background:
                            "linear-gradient(90deg, #F36A1F, #FF8C00, #FFA500, #FFBF00, #F36A1F)",
                          backgroundSize: "400% 400%",
                          animation: "progressBarGradient 3s ease infinite",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <ConnectButton
                      showBalance={false}
                      chainStatus={false}
                      className="animated-button"
                    />
                  </Box>
                  {isConnected && (
                    <Container maxWidth="sm" sx={{ mt: 2 }}>
                      <Typography>0</Typography>
                      <Typography gutterBottom align="center">
                        Select Amount
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ mt: 2, mb: 2 }}
                        justifyContent="center"
                      >
                        {[100, 500, 1000, 10000].map((value) => (
                          <Button
                            key={value}
                            variant="outlined"
                            onClick={() => setAmount(value)}
                            sx={{
                              ...styles,
                              borderColor: "white",
                              "&:hover": {
                                borderColor: "#F36A1F",
                                color: "#F36A1F",
                              },
                            }}
                            className="animated-button"
                          >
                            {value}
                          </Button>
                        ))}
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ mt: 2, mb: 2 }}
                        justifyContent="center"
                      >
                        <TextField
                          label="Amount"
                          value={
                            stats.presaleState === SaleState[2] ? "0" : amount
                          }
                          disabled={stats.presaleState === SaleState[2]}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          sx={{
                            input: { color: "white" },
                            label: { color: "white" },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                                "&:hover": {
                                  borderColor: "#F36A1F",
                                  color: "#F36A1F",
                                },
                              },
                            },
                          }}
                          slotProps={{
                            htmlInput: {
                              step: 320,
                              min: 0,
                              max: 10000,
                              type: "number",
                            }
                          }}
                        />
                        <TextField
                          label="Total Price"
                          value={
                            stats.presaleState === SaleState[2]
                              ? "Will be back soon"
                              : 0.00315
                          }
                          disabled={stats.presaleState === SaleState[2]}
                          sx={{
                            input: { color: "white" },
                            label: { color: "white" },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                                "&:hover": {
                                  borderColor: "#1px solid #F36A1F",
                                  color: "#F36A1F",
                                },
                              },
                            },
                          }}
                          slotProps={{
                            input: {
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={SpookipupLogo}
                                    alt="Spookipup Logo"
                                    style={{ height: 24 }}
                                  />
                                </InputAdornment>
                              ),
                            }
                          }}
                        />
                      </Stack>
                      {stats.presaleState === SaleState[2] && (
                        <Typography
                          variant="h6"
                          align="center"
                          sx={{
                            color: "#F36A1F",
                            fontWeight: "bold",
                            my: 2,
                          }}
                        >
                          Sale has been paused
                        </Typography>
                      )}
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          variant="contained"
                          onClick={initBuy}
                          disabled={
                            amount <= 0 || stats.presaleState === SaleState[2]
                          }
                          sx={{
                            mt: 2,
                            backgroundColor: "#F36A1F",
                            "&:disabled": {
                              backgroundColor: "#7F3A0F",
                            },
                            fontSize: "1.2rem",
                            padding: "12px 40px",
                            borderRadius: "25px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "all 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#D15A1F",
                              boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
                              transform: "translateY(-2px)",
                            },
                          }}
                        >
                          Buy Now
                        </Button>
                      </Box>
                    </Container>
                  )}
                </>
              )}
          </Box>

          <div className="external-buttons">
            <Button
              sx={{
                ...styles,
                borderColor: "white",
                "&:hover": { borderColor: "#F36A1F", color: "#F36A1F" },
              }}
              variant="outlined"
              className="external-btn"
            >
              Whitepaper
            </Button>

            <Button
              sx={{
                ...styles,
                borderColor: "white",
                "&:hover": { borderColor: "#F36A1F", color: "#F36A1F" },
              }}
              variant="outlined"
              className="external-btn"
            >
              Audit
            </Button>
            <a
              href="https://docs.spookipup.meme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                sx={{
                  ...styles,
                  borderColor: "white",
                  "&:hover": { borderColor: "#F36A1F", color: "#F36A1F" },
                }}
                variant="outlined"
                className="external-btn"
              >
                Docs
              </Button>
            </a>
          </div>

          <div className="social-icons">
            <a href="https://x.com" target="_blank" rel="noopener noreferrer">
              <Twitter fontSize="large" />
            </a>
            <a href="https://t.me" target="_blank" rel="noopener noreferrer">
              <Telegram fontSize="large" />
            </a>
          </div>
        </div>
      </div>
    </div>)
  );
};

export default Home;
