import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import NavBar from "./components/navigation/nav";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Home from "./components/home/home";
import About from "./components/about/about";
import HowToBuy from "./components/howtobuy/howToBuy";
import Tokenomics from "./components/tokenomics/tokenomics";
import Roadmap from "./components/roadmap/roadmap";
import FAQ from "./components/faq/Faq";
import Footer from "./components/footer/footer";
import logo from "./assets/logo.png";
import "./App.css"; // Import custom styles

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "transparent", // This allows our custom background to show through
    },
  },
  typography: {
    fontFamily: '"Comic Sans MS", cursive',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent app bar
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#ff6347", // Your hover color from the original CSS
          },
        },
      },
    },
  },
});

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const pages = [
    "Home",
    "About",
    "How to Buy",
    "Tokenomics",
    "Roadmap",
    "F.A.Q",
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              component="a"
              href={`#${text.toLowerCase().replace(/\s+/g, "")}`}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        className="app-background"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <NavBar toggleDrawer={toggleDrawer} logo={logo} />
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, mt: 8, width: "100%" }}>
          <Home />
          <About />
          <HowToBuy />
          <Tokenomics />
          <Roadmap />
          <FAQ />
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
