import React from "react";
import { useState, useEffect } from "react";
import AboutCard from "./aboutCard";
import data from "./about_data.json";
import "./about.css";
import dog from "../../assets/mascot.svg";

const About = () => {
  const [showImage, setShowImage] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setShowImage(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="about" className="about">
      <h1>OVERVIEW</h1>
      <div className="about-content-wrapper">
        <div className="about-content-left">
          <h2 className="about-h2">About</h2>
          <p>
            Welcome to the official docs of Spookipup. In the ever-evolving
            world of meme coins, where creativity meets cryptocurrency,
            Spookipup emerges as the latest sensation, a meme coin that’s as fun
            as it is spooky! Imagine a haunted pup with a playful yet eerie
            vibe, capturing the imagination of the crypto community. Spookipup
            isn't just another meme coin; it's a symbol of the fun and
            unpredictability of the crypto market, wrapped in the mysterious
            allure of the supernatural. Whether you're a seasoned trader or new
            to the meme coin craze, Spookipup offers a unique, spirited
            experience that promises to haunt your portfolio in the best way
            possible. Get ready to embrace the ghostly adventures with
            Spookipup.
          </p>
          <AboutCard data={data.features} />
        </div>
        {showImage && (
          <div className="about-content-right">
            <img
              src={dog}
              alt="Cute dog in ghost costume"
              className="about-image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
