import React from "react";
import logo from "../../assets/logo.png";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./footer.css"; // Import your CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <div className="logo-div">
            <img src={logo} alt="SpookiPup Logo" className="logo" />
            <h1>Spookipup</h1>
          </div>
          <div className="social-links">
            <a href="https://valid-link.com" className="hover:text-gray-300">
              <XIcon size={20} />
            </a>
            <a href="https://valid-link.com" className="hover:text-gray-300">
              <WhatsAppIcon size={20} />
            </a>
          </div>
          <nav className="nav-links">
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#howtobuy">How to Buy</a>
              </li>
              <li>
                <a href="#tokenomics">Tokenomics</a>
              </li>

              <li>
                <a href="#roadmap">Roadmap</a>
              </li>
              <li>
                <a href="#faq">F.A.Q</a>
              </li>
            </ul>
          </nav>
          <p className="disclaimer">
            Spookipup does not provide investment advice. All rights reserved.
            Cryptocurrency investments are volatile and high risk in nature.
            <br />
            Invest at your own risk.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
