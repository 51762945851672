import React from "react";
import FaqItem from "./FaqItem";
import data from "./faq_data.json";
import "./faq.css";

const FAQ = () => {
  return (
    <div id="faq" className="section">
      <h1>F.A.Q</h1>
      <p>Frequently Asked Questions.</p>
      <>
        {data.map((faq, idx) => (
          <FaqItem key={idx} question={faq.q} answer={faq.a} />
        ))}
      </>
    </div>
  );
};

export default FAQ;
