import React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
import "./howToBuy.css";
import stone2 from "../../assets/stone.svg";
import data from "./howtobuy_data.json";

const HowToBuy = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = useCallback(() => {
    setAnimationClass("slide-out-left");
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      setAnimationClass("slide-in-right");
    }, 200);
  }, []);

  const handlePrev = useCallback(() => {
    setAnimationClass("slide-out-right");
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + data.length) % data.length
      );
      setAnimationClass("slide-in-left");
    }, 200);
  }, []);

  const handleSwipe = useCallback(() => {
    if (touchStartX.current === null || touchEndX.current === null) return;

    const difference = touchStartX.current - touchEndX.current;
    if (difference > 50) {
      handleNext();
    } else if (difference < -50) {
      handlePrev();
    }
  }, [handleNext, handlePrev]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    handleSwipe();
    touchStartX.current = null;
    touchEndX.current = null;
  };

  return (
    <div id="howtobuy" className="how-to-buy">
      <h1>How to Buy</h1>
      <div
        className="slider"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className={`slide ${animationClass}`}>
          <img src={stone2} alt="Bone" className="bone-image" />
          <p className="text">{data[currentIndex].text}</p>
        </div>
        {windowWidth > 768 && (
          <>
            <button className="button-left" onClick={handlePrev}>
              &lt;
            </button>
            <button className="button-right" onClick={handleNext}>
              &gt;
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default HowToBuy;
