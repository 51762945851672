import React from "react";
import "./tokenomics.css";
import data from "./tokenomics.json";
import PieChart from "./PieChart";

const Tokenomics = () => {
  return (
    <div id="tokenomics" className="tokenomics-wrapper">
      <h1 className="tokenomics-heading">{data.title}</h1>
      <p className="tokenomics-heading">{data.description}</p>
      <div className="tokenomics-container">
        <div className="left-side">
          <PieChart />
        </div>
        <div className="right-side">
          {/* Table instead of image and text */}
          <table className="spkp-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Allocation</th>
                <th>Quantity ($SPKP)</th>
                <th>Usage</th>
              </tr>
            </thead>
            <tbody>
              {data.table.map((row) => (
                <tr key={row.category}>
                  <td>{row.category}</td>
                  <td>{row.allocation}%</td>
                  <td>${parseInt(row.quantity).toLocaleString()}</td>
                  <td>{row.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
