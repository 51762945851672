import React from "react";
import { useEffect, useState } from "react";
import "./roadmap.css";
import data from "./roadmap_data.json";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context("../../assets", false, /\.(png|jpe?g|svg)$/)
);

const Roadmap = () => {
  const [roadmapData, setRoadmapData] = useState([]);

  useEffect(() => {
    setRoadmapData(data);
  }, []);

  return (
    <div id="roadmap" className="roadmap">
      <h1>Roadmap</h1>
      <div className="roadmap-grid">
        {roadmapData.map((item, index) => (
          <div key={index} className="roadmap-item">
            <img src={images[item.image]} alt={item.title} />
            <div className="content">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
