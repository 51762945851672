import React from "react";
import { useState } from "react";
import "./faq.css";
const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleFAQ = () => setIsOpen(!isOpen);
  return (
    <div className="faqItem">
      <button onClick={toggleFAQ} className={`${isOpen ? "active" : ""}`}>
        <span>{question}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <p>{answer}</p>}
    </div>
  );
};

export default FaqItem;
